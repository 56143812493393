var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.types, function(control) {
          return _c(
            "div",
            { key: control.name, staticClass: "col-lg-3" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "service_catalog.hse.index.category",
                      params: { category: control.id }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card" }, [
                    _c("img", {
                      staticClass: "card-img img-fluid",
                      attrs: {
                        src: require("@/assets/images/base/controls/" +
                          control.id +
                          ".jpg"),
                        alt: control.name,
                        title: control.name
                      }
                    }),
                    _c("div", { staticClass: "card-body" }, [
                      _c("h5", { staticClass: "card-title text-center" }, [
                        _vm._v(_vm._s(control.name))
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }